export enum TipoCategoriaEnum{
    CodigoTipoTransaccion = 'CATTRANSAC',
    CodigoTipoAsiento = 'CATASIENTO',
    CodigoTipoCompraVenta = 'CATCOMVEN',
    CodigoTipoComprobante = 'CATTCOMPRO',
    CodigoTipoNaturalezaContable = 'CATNATURCU',
    CodigoTipoConfiguracion = 'CATTIPCONF',
    CodigoFormatoCampoConfiguracion = 'CATFORCAMC',
    CodigoAreaFogapy = 'CATAREA',
    CodigoCasosPagoGarantia = 'CATCASOGAR',
    CodigoFuncionesGarantias = 'CATFUNCIONGAR',
    CodigoMotivoGarantias = 'CATSOLICITUDPAGO',
    CodigoProcesoEstadoGarantia = 'CATPROCESOGARANTIA',
    CodigoAccionDevolverLegajo = 'DEVOLVERLEGAJOPAGO',
    CodigoAccionDevolverAnalisis = 'DEVOLVERANALISISPAGO',
    CodigoCategoriaTipoAccion = 'CATFUNCIONGAR',
    CodigoAccionProrroga = 'PRORROGA',
    CodigoTipoCuentaPlanCuenta = 'TIPO_CUENTA_CONTABLE',
    CodigoTipoDocumentoSolicitudPago = 'CATDSPGRTIA',
    CodigoTipoDocumentoFinanciamientoOtorgado = 'CATDFINOTGD',
    CodigoTipoDocumentoAcreditacionCalBenefi = 'CATACCLBEN',
    CodigoTipoDocumentoCobroDirectExtraJud = 'CATDCOBDEX',
    CodigoTipoDocumentoAccionJudicialDemanEject = 'CATAJDEJEC',
    CodigoTipoDocumentoAccionJudicialConvAcreedor = 'CATAJCVACR'
}