import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { UnauthorizedComponent} from './pages/unauthorized/unauthorized.component'

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard/inicio',
        canActivate: [AuthGuard],
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                canActivate:[AuthGuard],
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                data: {expectedRol:['SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_INF_ADM',
                    'ROLE_ENCARGADO_FONDO','ROLE_SFG_GERENTE_GENERAL','ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_SFG_CONTADOR_FOGAPY'],
                expectedPermission:['P965']}
            },
            {
                path: 'contabilidad',
                canActivate:[AuthGuard],
                loadChildren: './administracion/contabilidad/contabilidad.module#ContabilidadModule',
                data: {expectedRol:['SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_CONT_INF','SFG_INF_ADM',
                'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO'],
                expectedPermission:['P966' ]}

            },
            {
                path: 'reportes',
                loadChildren: './administracion/reportes/reportes.module#ReportesModule',
                data: {expectedRol:['ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO',
                 'ROLE_ANALISTA_LEGAL', 'SFG_GERENTE_GENERAL','ROLE_COMERCIAL_FONDO'],
                expectedPermission:['P967']}

            },
            {
                path: 'garantia',
                canActivate:[AuthGuard],
                loadChildren: './administracion/garantia/garantia.module#GarantiaModule',
                data: {expectedRol:['ROLE_FOGAPY','ADMINISTRADOR_IFP', 'ROLE_AREA_LEGAL', 'ROLE_GERENCIA_RIESGOS' , 'SFG_CONT_REG', 'SFG_CONT_AUT', 'SFG_CONT_INF','SFG_INF_ADM',
                'ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD','ROLE_ENCARGADO_FONDO','ROLE_COMERCIAL_FONDO','ROLE_ANALISTA_LEGAL'],
                expectedPermission:['P968']}

            },
            {
                path: 'mantenimiento',
                canActivate:[AuthGuard],
                loadChildren: './administracion/mantenimiento/mantenimiento.module#MantenimientoModule',
                data: {expectedRol:['ROLE_SFG_ADM_TI','ROLE_SFG_CONTADOR_FOGAPY', 'ROLE_SFG_ANALISTA_CONTABILIDAD'],
                expectedPermission:['P964']}
            },
            {
                path: 'panelcontrol',
                loadChildren: './administracion/panel-control/panel-control.module#PanelControlModule',
                data: {expectedRol:['ROLE_SFG_ADM_TI', 'SFG_INF_ADM'],
                expectedPermission:[]}
            },
            {
                path: 'seguimientotarea',
                loadChildren: './administracion/seguimiento-tarea/seguimiento-tarea.module#SeguimientoTareaModule'


            },
            {
                path: 'configuracion',
                loadChildren: './administracion/configuracion/configuracion.module#ConfiguracionModule',
                data: {expectedRol:['SFG_CONFIGURADOR_SISTEMA', 'SFG_CONFIGURADOR_USUARIO', 'ROLE_SFG_ADM_TI'],
                expectedPermission:['P']}
            }, {
                path: 'unauthorized',
                component: UnauthorizedComponent
            }

        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];